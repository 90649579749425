<template>
  <div>
    <title>SIM INVENTORY ~ UPDATE DATA LIST OF FINISH GOOD ITEMS</title>
    <section class="content-header">
      <h1>
        Update Data List of Finish Good Items
        <br />
        <h4>Please enter transaction data for the list of finish good items</h4>
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Transaction List Finish Good Items</li>
      </ol>
    </section>
    <section class="content">
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>
      
      <div class="row">
        <!-- left column -->
        <div class="col-md-6">
          <!-- general form elements -->
          <div class="box box-primary">
            <div class="box-header with-border">
              <i class="fa fa-edit"></i>
              <h3 class="box-title">Update Finish Good Items List Data</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">Receipt Number </label>
                  <input
                    type="hidden"
                    readonly
                    v-model="idxpenh"
                    autocomplete="off"
                    class="form-control"
                  />
                  <input
                    type="text"
                    v-model="receiptno"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Receipt Type</label>
                  <select class="form-control" v-model="jenispenerimaan" id="jenispenerimaan">
                    <option disabled>--Please select--</option>
                    <option
                      v-for="datarectyp in rectyp"
                      :key="datarectyp.id"
                      :value="datarectyp.value"
                    >
                      {{ datarectyp.value }}
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">BL Number / Travel Document </label>
                  <input
                    type="text"
                    v-model="blnumber"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="row">
                  <div class="col-xs-9">
                    <label for="exampleInputEmail1">Supplier Code</label>
                    <input
                      type="text"
                      v-model="kodesupplier"
                      autocomplete="off"
                      class="form-control"
                      placeholder="Please enter supplier code"
                    />
                  </div>
                  <div class="col-xs-3">
                    <label for="exampleInputEmail1">Action</label>
                    <br />
                    <button
                      type="button"
                      @click="getsupplier()"
                      class="btn btn-info"
                    >
                      Search
                    </button>
                  </div>
                </div>
                <br />
                <div class="form-group">
                  <label for="exampleInputEmail1">Supplier Name </label>
                  <input
                    type="text"
                    readonly
                    v-model="namasupplier"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Notes </label>
                  <input
                    type="text"
                    v-model="notespenh"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Receipt Date </label>
                  <input
                    type="date"
                    v-model="tglreceipt"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="box-footer">
                <button
                  @click="updateData()"
                  type="button"
                  class="btn btn-primary"
                ><i class="fa fa-save"></i>
                  Update
                </button>
                &nbsp;
                <router-link to="/finishgood">
                  <button class="btn btn-success"><i class="fa fa-angle-double-left"></i> Back</button>
                </router-link>
              </div>
            </form>
          </div>
        </div>
        <div class="col-md-6">
          <!-- general form elements -->
          <div class="box box-primary">
            <div class="box-header with-border">
              <i class="fa fa-edit"></i>
              <h3 class="box-title">Update Finish Good Items List Data</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">Customs Document Code </label>
                  <input
                    type="hidden"
                    readonly
                    v-model="idxtpbheader"
                    autocomplete="off"
                    class="form-control"
                  />
                  <input
                    type="text"
                    readonly
                    v-model="kodedokumenpabean"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Register Number </label>
                  <input
                    type="text"
                    readonly
                    v-model="nomordaftar"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Register Date </label>
                  <input
                    type="text"
                    readonly
                    v-model="tgldaftar"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import swal from "sweetalert";
var axios = require("axios");
export default {
  name: "addmasterdata",
  data() {
    return {
      rectyp: [
        { value: "INTERNAL", id: 1 },
        { value: "EXTERNAL", id: 2 }
      ],
      loading: false,
      idxtpbheader: "",
      kodedokumenpabean: "",
      nomordaftar: "",
      tgldaftar: "",
      idxpenh: "",
      receiptno: "",
      jenispenerimaan: "",
      kodesupplier: "",
      namasupplier: "",
      notespenh: "",
      tglreceipt: "",
      blnumber: ""
    };
  },
  created() {
    this.loadData();
    this.idxpenh = this.$route.params.id;
  },
  methods: {
    loadData() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      const urlAPIget =
        this.$apiurl +
        "penerimaan_fg_h/getpenerimaan_fg_hbyid?id=" +
        this.$route.params.id;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
        //   console.log(resp);
          this.idxpenh = resp.data.data.id;
          this.idxtpbheader = resp.data.data.auto_tpb;
          this.kodedokumenpabean = resp.data.data.jenis_bc;
          this.nomoraju = resp.data.data.nama_supplier;
          this.nomordaftar = resp.data.data.bc_no;
          this.tgldaftar = resp.data.data.bc_date;
          this.jenispenerimaan = resp.data.data.jenis_penerimaan;
          this.receiptno = resp.data.data.receipt_number;
          this.kodesupplier = resp.data.data.kode_supplier;
          this.namasupplier = resp.data.data.nama_supplier;
          this.notespenh = resp.data.data.notes;
          this.tglreceipt = resp.data.data.receipt_date;
          this.blnumber = resp.data.data.bl_number;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getsupplier() {
      this.loading = true;
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/supplier/getsupplierbykode?kode_supplier=" + this.kodesupplier;
      const urlAPIget =
        this.$apiurl +
        "supplier/getsupplierbykode?kode_supplier=" +
        this.kodesupplier;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          if (resp.data.pesan == "Data not found") {
            swal({
              icon: "warning",
              title: "Warning",
              text: "Sorry, the supplier you were looking for could not be found",
              showConfirmButton: false
            });
            this.namasupplier = ""
          } else {
            this.namasupplier = resp.data.data.nama_supplier;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async updateData() {
      this.loading = true;
      var idxpeneh = this.idxpenh;
      var norec = this.receiptno;
      var jenpenerimaan = this.jenispenerimaan;
      var kdsup = this.kodesupplier;
      var nmsup = this.namasupplier;
      var catatan = this.notespenh;
      var tglrec = this.tglreceipt;
      var nobl = this.blnumber;
      if (norec == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Receipt Number can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (kdsup == "" || nmsup == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Supplier Code / Supplier Name can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (catatan == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Notes can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (jenpenerimaan == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Receipt Type can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (tglrec == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Receipt Date can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (nobl == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "BL Number can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        var kodeuser = sessionStorage.getItem("kodeuser");
        const paramdatadetail = {
          receipt_number: this.receiptno,
          kode_supplier: this.kodesupplier,
          jenis_penerimaan: this.jenispenerimaan,
          notes: this.notespenh,
          receipt_date: this.tglreceipt,
          kode_user: kodeuser,
          auto_tpb: this.idxtpbheader,
          bl_number: this.blnumber,
          bc_no: this.nomordaftar,
          bc_date: this.tgldaftar,
          jenis_bc: this.kodedokumenpabean
        };
        // const tokenlogin = localStorage.getItem("token");
        const tokenlogin = sessionStorage.getItem("token");
        const headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };

        const urlAPIUpdateData =
          this.$apiurl + "penerimaan_fg_h/updatepenerimaan_fg_h/" + idxpeneh;
        axios
          .put(urlAPIUpdateData, paramdatadetail, { headers: headers })
          .then((respn) => {
            if (respn.data.status == true) {
              swal({
                icon: "success",
                title: "Success",
                text: "Update data successfully",
                showConfirmButton: false
              });
              this.loading = false;
              this.$router.push({ name: "finishgoodheader" });
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Update data failed",
                showConfirmButton: false
              });
              this.loading = false;
              this.$router.push({ name: "finishgoodheader" });
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false
            });
            console.log(err);
            this.loading = false;
          });
      }
    }
  }
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
